<template>
    <div>

<DoneAlert :msg="$t('Data has been updated')" :visible="ShowDoneAlert" />
 <b-container  class=" mt-5" >
     
<b-card bg-variant="light" :header="$t('Signup')" class="text-right">
<br>   
<b-form @submit="onSubmit"  v-if="show"  enctype="multipart/form-data">
<b-row>
    <b-col cols="12"  > 
    <div>
    <b-alert :show="ShowError" dismissible variant="danger">
      
    {{errorMgs}}

    </b-alert>
     </div>
     </b-col>
</b-row>

<b-row align-h="center">
<b-col cols="12" lg="6" xl="6" > 

    
         <!-- input -->
      <b-form-group  :label="$t('first_name')" label-for="input-1" :class="{ 'form-group--error': $v.form.name.$error }">
        <b-form-input  v-model.trim="form.name" @input="setTouched('name')" type="text" required ></b-form-input>
         <b-form-invalid-feedback  v-if="$v.form.name.$error"  :state="$v.form.name.required"> {{$t('required_form')}} </b-form-invalid-feedback>
      </b-form-group>
      
        <!-- input -->
      <b-form-group :label="$t('Email')" label-for="input-1" :class="{ 'form-group--error': $v.form.email.$error }">
        <b-form-input   v-model.trim="form.email" @input="setTouched('email')" type="email" required ></b-form-input>
         <b-form-invalid-feedback v-if="$v.form.email.$error" :state="$v.form.email.required"> {{$t('required_form')}} </b-form-invalid-feedback>
         <b-form-invalid-feedback v-if="$v.form.email.$error" :state="$v.form.email.email"> {{$t('email_form')}} </b-form-invalid-feedback>
         <div v-if="errorsBack.email" >
            <b-form-invalid-feedback v-for="error in errorsBack.email" :key="error"   :state="false"> {{error}}</b-form-invalid-feedback>
         </div>
      </b-form-group>
       <!-- input -->
      <b-form-group  :label="$t('Phone')" label-for="input-1" :class="{ 'form-group--error': $v.form.phone.$error }">
        <b-form-input  v-model.trim="form.phone" @input="setTouched('phone')" type="text"  ></b-form-input>
         <b-form-invalid-feedback  v-if="$v.form.phone.$error"  :state="$v.form.phone.numeric"> {{$t('phone_form')}} </b-form-invalid-feedback>
           <div v-if="errorsBack.phone" >
            <b-form-invalid-feedback v-for="error in errorsBack.phone" :key="error"   :state="false"> {{error}}</b-form-invalid-feedback>
         </div>
      </b-form-group>
     
 <!-- input -->
      <b-form-group  :label="$t('talent')" label-for="input-3" :class="{ 'form-group--error': $v.form.category.$error }">
        <b-form-select  v-model="form.category" :options="categories" @input="setTouched('category')" required ></b-form-select>
        <b-form-invalid-feedback v-if="$v.form.category.$error" :state="$v.form.category.required"> {{$t('required_form')}} </b-form-invalid-feedback>
      </b-form-group>
      
    
   
      
   </b-col>

   <b-col cols="12" lg="6" xl="6" > 
        <!-- input -->
      <b-form-group  :label="$t('last_name')" label-for="input-1" :class="{ 'form-group--error': $v.form.last_name.$error }">
        <b-form-input   v-model.trim="form.last_name" @input="setTouched('last_name')" type="text" required ></b-form-input>
         <b-form-invalid-feedback v-if="$v.form.last_name.$error" :state="$v.form.last_name.required"> {{$t('required_form')}} </b-form-invalid-feedback>
      </b-form-group>
 
        <!-- input -->
      <b-form-group  :label="$t('Password')" label-for="input-2" :class="{ 'form-group--error': $v.form.password.$error }">
        <b-form-input  v-model="form.password" type="password" @input="setTouched('password')"  ></b-form-input>
        <b-form-invalid-feedback v-if="$v.form.password.$error" :state="$v.form.password.required"> {{$t('required_form')}} </b-form-invalid-feedback>
      </b-form-group>
       <!-- input -->
      <b-form-group  :label="$t('password_confirmation')" label-for="input-2" :class="{ 'form-group--error': $v.form.confirm_password.$error }">
        <b-form-input  v-model="form.confirm_password" type="password" @input="setTouched('confirm_password')"  ></b-form-input>
        <b-form-invalid-feedback v-if="$v.form.confirm_password.$error" :state="$v.form.confirm_password.required"> {{$t('required_form')}} </b-form-invalid-feedback>
        <b-form-invalid-feedback v-if="$v.form.confirm_password.$error" :state="$v.form.confirm_password.sameAsPassword"> {{$t('passwordconfirmation')}} </b-form-invalid-feedback>
      </b-form-group>


   </b-col>
   </b-row>
   <b-row>
    <b-col cols="12"  >
<!-- input -->
    <b-form-group :label="$t('disability_type')" v-slot="{ ariaDescribedby } " :class="{ 'form-group--error': $v.form.disability_type.$error }">
      <b-form-checkbox-group
        id="checkbox-group-1"
        v-model="form.disability_type"
        :options="options"
        :aria-describedby="ariaDescribedby"
        name="flavour-1"
        @input="setTouched('disability_type')"
      ></b-form-checkbox-group>
       <b-form-invalid-feedback v-if="$v.form.disability_type.$error" :state="$v.form.disability_type.required"> {{$t('required_form')}} </b-form-invalid-feedback>
    </b-form-group>

    <!-- input -->
      <b-form-group  :label="$t('disability_description')" label-for="input-1" :class="{ 'form-group--error': $v.form.disability_description.$error }">
        <b-form-textarea   v-model.trim="form.disability_description" @input="setTouched('disability_description')" type="text" required rows="3"
      max-rows="6" ></b-form-textarea>
         <b-form-invalid-feedback v-if="$v.form.disability_description.$error" :state="$v.form.disability_description.required"> {{$t('required_form')}} </b-form-invalid-feedback>
      </b-form-group>

     </b-col>
</b-row>
<b-row>
    <b-col cols="12"  >
    <b-button type="submit" variant="primary"><b-spinner v-if="isSendingForm" small></b-spinner>  {{$t('Save')}}</b-button>
     </b-col>
</b-row>






    </b-form>
     </b-card>
</b-container>

</div>




</template>

<script>

import axios from 'axios'
import { required, email,sameAs,numeric,minLength   } from 'vuelidate/lib/validators'
import DoneAlert from '@/components/alerts/DoneAlert.vue'
export default {
        data() {
      return {
        form: {
            name: null,
            last_name: null,
            email: null,
            phone:null,
            password: null,
            confirm_password:null,
            category:null,
            disability_description:null,
            disability_type:[],
           

        },
        selected:[],
        options: [
            { text: 'اعاقة سمعية', value: 'hearing' },
            { text: 'اعاقة بصرية', value: 'visual' },
            { text: 'اعاقة حركية', value: 'moving' },
            { text: 'اعاقة ذهنية', value: 'mental' },
            { text: 'اخرى', value: 'other' },
         ],
        statuses: [ 'active', 'deactivated', 'waiting review'],
        categories:[],
        errorsBack:{},
        errorMgs:'',
        ShowError: false,
        show: true,
        ShowDoneAlert:false,
        isSendingForm:false
      }
    },
    components:{
         DoneAlert
        
    },
     validations: {
      form:{
        phone:{numeric},
        category: {  required },
        name: {  required },
        last_name: { required },
        email: { required,email},
        password: { minLength: minLength(6)},
        disability_description:{required},
        disability_type:{required},
        confirm_password: { sameAsPassword: sameAs('password') }
    }
  },
  /***************** */
      methods: {
      fillForm(Thedata){
        this.form.name = Thedata.name;
        this.form.last_name = Thedata.last_name;
        this.form.email = Thedata.email;
        this.form.phone = Thedata.phone;
        this.form.category = Thedata.category;
        this.form.disability_description = Thedata.disability_description;
        this.form.disability_type = JSON.parse(Thedata.disability_type);
        this.main_id= Thedata.id;

    //console.log("zxcxz-----------"+ this.form);


      },
     setTouched(theModel) {
       if(theModel == 'phone' || theModel == 'all' ){this.$v.form.phone.$touch()}
       if(theModel == 'category' || theModel == 'all' ){this.$v.form.category.$touch()}
       if(theModel == 'status' || theModel == 'all' ){this.$v.form.status.$touch()}
       if(theModel == 'name' || theModel == 'all' ){this.$v.form.name.$touch()}
       if(theModel == 'last_name' || theModel == 'all' ){this.$v.form.last_name.$touch()}
        if(theModel == 'email' || theModel == 'all' ){this.$v.form.email.$touch()}
        if(theModel == 'password' || theModel == 'all'){this.$v.form.password.$touch()}
        if(theModel == 'confirm_password' || theModel == 'all'){this.$v.form.confirm_password.$touch()}
        if(theModel == 'disability_description' || theModel == 'all'){this.$v.form.disability_description.$touch()}
        if(theModel == 'disability_type' || theModel == 'all'){this.$v.form.disability_type.$touch()}
      
    },
        fixingOption(json){
          var array=[];
          for (let index = 0; index < json.length; index++) {
            array[index] ={"text":this.$t(json[index].name),"value":json[index].id} ;
            
          }
           return array;
        },
      getCreateData(){
      axios.get(this.$store.state.backendUrl+'api/ar/profile', {
            headers: {
           'Authorization': 'Bearer ' + this.$store.state.token,
            "Content-Type": "application/json",
            }
            })
            .then((response) => {
                console.log(response);
                if(response.Error){this.ShowError=true;this.errorMgs = this.$t('error_login');}
                else{
                  this.fillForm(response.data.success.user);
                 this.categories = this.fixingOption (response.data.success.categories);
                 console.log(this.roles);
                }
            })
            .catch( (error) => {
                console.log(error);
                this.ShowError=true;
                this.errorMgs = error;
                 if(error){
                    this.errorsBack = error.response.data.errors;
                     console.log(error.response.data.errors);
                  }
                
            });
           
      } , 
      onSubmit(event) {
        event.preventDefault()
        if (!this.$v.$invalid) {

       this.isSendingForm = true;
        this.form.disability_type = JSON.stringify(this.form.disability_type);
        const formData = new FormData();
        for (const [key, value] of Object.entries(this.form)) {
              if(value != null){
             formData.append(key,value)
              console.log(key, value);
              }
          }
      
        axios.post(this.$store.state.backendUrl+'api/ar/profile',formData, {
            headers: {
           'Authorization': 'Bearer ' + this.$store.state.token,
            "Content-Type": "application/json",
            }
            })
            .then((response) => {
                console.log(response);
               
                  console.log(response.data.success);
                  this.isSendingForm = false;
                  this.ShowError=false;
                  this.errorMgs = "";
                  this.ShowDoneAlert = true;
                  setTimeout(()=>{     
                  this.ShowDoneAlert = false;
                  }, 3000);
                  this.getCreateData();
            })
            .catch( (error) => {
                console.log(error.response);
                this.ShowError=true;
                this.errorMgs = error;
                this.isSendingForm = false;
                 if(error){
                    this.errorsBack = error.response.data.errors;
                     console.log(error.response.data.errors);
                  }
            });
           }
      },
     
      ResetForm() {
        
        // Reset our form values
       
        this.form.name = null,
        this.form.last_name = null,
         this.form.email = null,
        this.form.phone = null,
        this.form.password = null,
        this.form.status = null,
        this.form.role = null,
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
      }
    },
    /********************* */
    mounted() {
      this.getCreateData();
      //this.form.lang_id = this.$i18n.locale;
    }
    
}
</script>